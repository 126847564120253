@mixin basebutton {
    display: inline-flex;
    transition: $fast;
    border-radius: 18px;
    cursor: pointer;
    height: 36px;
    vertical-align: top;
    text-align: center;
    text-decoration: none;
    line-height: 36px;
    font-size: 16px;
    box-sizing: border-box;
    transition-property: background, color, border;

    align-items: center;
    justify-content: center;

    svg {
        transition: $fast;
        transition-property: fill;
    }

    &[disabled] {
        opacity: 0.5;

        .btn-loading {
            opacity: 1;
        }
    }
}
