input {
    &[type='number'] {
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            margin: 0;
            -webkit-appearance: none;
        }
        -moz-appearance: textfield;
    }

    &[maxlength='20']::-webkit-contacts-auto-fill-button {
        display: none !important;
        position: absolute;
        right: 0;
        visibility: hidden;
        pointer-events: none;
    }
}
