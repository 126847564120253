@import '../../mixins/scrollbar';

.searchbox {
    & + .multiselect {
        margin-top: 20px;
    }
}

.multiselect {
    border: 1px solid $light-grey;
    overflow: auto;
    font-size: 15px;

    .radio,
    .checkbox {
        position: absolute;
        top: 13px;
        left: 12px;

        &.radio-checked + label,
        &.checkbox-checked + label {
            background: rgba($lighter, 0.5);
        }
    }

    .group {
        position: relative;
        border-bottom: 1px solid $light-grey;

        &:last-child {
            border-bottom: 0;
        }

        ul {
            border-top: 1px solid $light-grey;

            &:empty {
                border: 0;
            }
        }

        .radio,
        .checkbox {
            z-index: 1;
        }
    }

    .group > button {
        @extend %nowrap;
        display: block;
        position: relative;
        border-radius: 0;
        background: rgba($light, 0.3);
        padding: 0 12px;
        width: 100%;
        height: 42px;
        text-align: left;
        color: $dark-grey;

        small {
            display: inline-block;
            position: absolute;
            top: 12px;
            margin-left: 10px;
            border: 1px solid $light-grey;
            border-radius: 3px;
            padding: 0 5px;
            min-width: 18px;
            height: 18px;
            line-height: 15px;
            color: $grey;
            font-size: 12px;
            font-weight: bold;
            box-sizing: border-box;
        }

        &::after {
            @include triangle(8px, $grey, down);
            position: absolute;
            top: 50%;
            right: 14px;
            transform: translateY(-50%);
            content: '';
        }

        &:hover {
            background: rgba($light, 0.6);
        }

        &.open {
            &::after {
                @include triangle(8px, $grey, up);
                border-top: 0;
            }
        }
    }

    .no-items {
        display: block;
        padding: 5px 18px;
        line-height: 32px;
        color: $grey;
    }

    label {
        display: block;
        cursor: pointer;
        padding: 13px 10px 13px 42px;
    }

    ul {
        li {
            position: relative;
            border-bottom: 1px solid $light-grey;
            min-height: 42px;
            line-height: 16px;

            label {
                @extend %nowrap;
                color: $dark-grey;

                font-size: 14px;
            }

            figure {
                position: relative;
                float: left;
                margin-top: -5px;
                margin-right: 12px;
                margin-bottom: -5px;
                width: 26px;
                height: 26px;
            }

            small {
                font-size: 12px;
            }

            &:last-child {
                border: 0;
            }

            &.non-selectable {
                border-bottom: 1px solid $light-grey;
                font-weight: var(--medium);
            }
        }
    }

    .btn-action {
        position: absolute;
        top: 10px;
        left: 4px;
    }

    .multiselect-collapsed {
        background: $light;
        padding: 12px;

        .multiselect {
            background: white;
        }

        ul {
            border-top: 0;
        }
    }
}
