[role='button'] {
    cursor: pointer;
}

#{$all-buttons} {
    margin: 0;
    border: 0;

    &:focus {
        outline: none;
    }

    &.inline {
        @extend %inlinebutton;
    }
}

.btn {
    .loading {
        @include loading($brand, white, 10px);
    }
}

.btn-loading {
    .loading {
        @include loading(white, white, 10px);
        top: 2px;

        &:first-child {
            margin-right: 6px;
        }

        &:last-child {
            margin-left: 6px;
        }
    }
}

.btn-action {
    @extend %actionbutton;
    .loading {
        @include loading($brand, white, 28px);
    }
    &.btn-small {
        .loading {
            width: 16px;
            height: 16px;
        }
    }
}

// linked style buttons
.btn-link-inline {
    @extend %inlinebutton;

    &.btn-brand {
        color: $brand;

        &:focus,
        &:hover {
            color: darken($brand, $darken);
        }
    }

    &.btn-success {
        color: var(--success);

        &:focus,
        &:hover {
            color: darken($success, $darken);
        }
    }

    &.btn-warning {
        color: var(--warning);

        &:focus,
        &:hover {
            color: darken($warning, $darken);
        }
    }

    &.btn-danger {
        color: var(--danger);

        &:focus,
        &:hover {
            color: darken($danger, $darken);
        }
    }

    &:hover {
        background: transparent;
        text-decoration: underline;
    }
}
