table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;

    th {
        text-align: left;
    }

    tr,
    td {
        padding: 0 10px;

        &:first-child {
            padding-right: 0;
            padding-left: 0;
        }
    }

    td {
        &:last-child {
            padding-right: 0;
        }
    }

    &.address {
        td {
            &.number {
                padding: 0 0 0 10px;
                width: 95px;
            }

            &.zipcode {
                width: 108px;
            }

            &.city {
                padding: 0 0 0 10px;
            }
        }
    }
}
