@import './dropdown.scss';

%select-base {
    @extend %dropdown;

    margin-left: 0;
    outline: 0;
    border: 1px solid var(--border);
    border-radius: var(--input-radius);
    
    background-color: white;
    background-position: right 14px center;
    padding: 0 35px 0 17px;
    height: $input-height;
    color: $dark-grey;

    &.placeholder:not(:focus) {
        color: $grey;
    }

    font-size: 16px;
    appearance: none;

    &:focus {
        font-weight: normal;
    }

    &::-moz-focusring {
        text-shadow: 0 0 0 $dark-grey;
        color: transparent;
    }

    &.small {
        border-radius: 4px;
        background-repeat: no-repeat;
        background-position: right 6px top 10px;
        padding: 0 21px 0 10px;
        height: $input-small-height;
        line-height: 30px;
        font-size: 14px;
        font-weight: var(--medium);
    }

    &.wide {
        width: 100%;
    }
}

%select-inline {
    margin: 0;
    outline: 0;
    border: 0;
    border-radius: 0;
    background-color: transparent;
    padding: 0;
    height: $input-height;
    color: rgba($grey, 0.01);
    font-weight: normal;
}
