:root {
    --sys-color-light-on-surface: #434D56;
    
    --sys-color-light-surface-container: #EEF0F2;
    --sys-color-light-surface-container-rgb: 238, 240, 242;

    --sys-color-light-surface-container-low: #F4F5F6;

    --sys-color-light-outline-variant: #C6CDD2;

    --sys-state-layer-light-surface-enabled: #E2E6E9;
    --sys-state-layer-light-surface-hovered: #C6CDD2;
    --sys-state-layer-light-surface-pressed: #A9B3BC;

    --systexttitlesmallsize: 16px;

    --systextbodylargesize: 16px;
    --systextbodymediumsize: 15px;
    --systextbodysmallsize: 14px;
}
