@import 'settings';

::-webkit-credentials-auto-fill-button {
    position: absolute;
    right: 0;
    visibility: hidden;
    pointer-events: none;
}

@import 'elements/table';
@import 'elements/stock';

@import 'forms/base';
@import 'forms/multiselect';
@import 'forms/check';
@import 'forms/add-table';

@import 'components/input';
@import 'components/stock';