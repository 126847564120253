.btn {
    cursor: pointer;

    height: 48px;
    min-width: 48px;
    box-sizing: border-box;
    padding: 0 24px;
    
    border-radius: 24px;
    background-color: white;
    border-width: 1px;
    border-color: var(--border);
    border-style: solid;
    
    
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    transition-property: background-color, border-color, color;
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;

    font-size: 14px;
    font-weight: 700;
    color: var(--sys-color-light-on-surface);

    svg {
        width: 18px;
        height: 18px;
        fill: var(--sys-color-light-on-surface);

        transition: fill 0.2s ease-in-out, transform 0.2s ease-in-out;

        &:only-child {
            width: 32px;
            height: 32px;
        }
    }

    &:has(> svg:first-child) {
        padding-left: 16px;
    }

    &:has(> svg:last-child) {
        padding-right: 16px;
    }

    &:has(svg:only-child) {
        padding: 0;
    }

    &:hover {
        border-color: var(--brand);
        color: var(--brand);

        svg {
            fill: var(--brand);
        }
    } 

    &:active {
        color: white;
        background-color: var(--brand);
        
        svg {
            fill: white;
        }
    }

    &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }
}

.btn-flex {
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--text);

    &:hover {
        text-decoration: underline;
    }
}


a.btn {
    text-decoration: none;
}

.btn-small {
    height: 36px;
    min-width: 36px;

    svg {
        width: 16px;
        height: 16px;

        &:only-child {
            width: 24px;
            height: 24px;
        }
    }
}

.btn-micro {
    height: 18px;
    min-width: 18px;

    svg, 
    svg:only-child { 
        width: 16px;
        height: 16px;
    }
}


.btn-radius-small {
    border-radius: 4px;
}

.btn-transparent {
    background: transparent;
    border-color: transparent;
}

.btn-open {
    svg {
        transform: rotate(180deg);
    }
}

.btn-success, 
.btn-warning, 
.btn-danger, 
.btn-brand,
.btn-info, 
.btn-light {
    color: white;
    border: none;

    svg {
        fill: white;
    }

    &:active,
    &:hover {
        color: white;
        svg {
            fill: white
        }
    } 
}

.btn-success {
    background: var(--success);
    &:hover {
        background-color: darken($success, 8%);
    }
    &:active {
        background-color: darken($success, 12%);
    }
}

.btn-warning {
    background: var(--warning);
    &:hover {
        background-color: darken($warning, 8%);
    }
    &:active {
        background-color: darken($warning, 12%);
    }
}

.btn-danger {
    background: var(--danger);
    &:hover {
        background-color: darken($danger, 8%);
    }
    &:active {
        background-color: darken($danger, 12%);
    }
}

.btn-brand {
    background: var(--brand);
    &:hover {
        background-color: darken($brand, 8%);
    }
    &:active {
        background-color: darken($brand, 12%);
    }
}

.btn-info {
    background: var(--info);
    &:hover {
        background-color: darken($info, 8%);
    }
    &:active {
        background-color: darken($info, 12%);
    }
}


.btn-light {
    background: var(--sys-state-layer-light-surface-enabled);
    &:hover {
        background: var(--sys-state-layer-light-surface-hovered);
    }
    &:active {
        background: var(--sys-state-layer-light-surface-pressed);
    }
}

.btn-warning-outline {
    &:hover {
        border-color: var(--warning);
        color: var(--warning);
        svg {
            fill: var(--warning);
        }
    } 
    &:active {
        background-color: var(--warning);
        svg {
            fill: white;
        }
    }
}

.btn-success-outline {
    border-color: var(--success);
    color: var(--success);
    svg {
        fill: var(--success);
    }

    &:hover {
        border-color:darken($success, 8%);
        color: darken($success, 8%);
        svg {
            fill: darken($success, 8%);
        }
    } 
    &:active {
        background-color: darken($success, 8%);
        svg {
            fill: white;
        }
    }
}

.btn-link {
    color: var(--brand);
    text-decoration: underline;
    border-color: transparent;
    &:hover {
        text-decoration: none;
    }
}