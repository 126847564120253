html,
body {
    height: 100%;
}

body {
    margin: 0;
    padding: 0;
    max-width: 100%;
    overflow: hidden;
    overflow-y: auto;
    overflow-x: hidden;
}

body {
    @include font-rendering;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    line-height: 135%;
    color: var(--dark-grey);
}

input,
button,
select,
textarea,
optgroup,
option {
    font-family: inherit;
    font-size: inherit;
    font-style: inherit;
    font-weight: inherit;
}

figure {
    margin: 0;
}

p {
    margin-bottom: 16px;

    &.lead {
        font-size: 18px;
    }
}

svg {
    display: inline-block;
}

a,
.btn-link-inline {
    text-decoration: none;
    color: var(--brand);

    &:hover {
        text-decoration: underline;
    }

    &:focus {
        outline: none;
    }
}

.btn-link-inline {
    strong {
        font-weight: normal;
        color: var(--brand);
    }
}

.link {
    text-decoration: underline;

    &:hover {
        color: var(--brand);
    }
}

a,
button {
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

small {
    color: $grey;
    font-size: 16px;
}

address {
    font-style: normal;
}

.loading {
    &,
    &::after {
        width: 14px;
        height: 14px;
    }

    @include loading($brand, $grey);

    h2 & {
        @include loading($brand, $grey);
        position: relative;
        top: 3px;
        margin: 0 14px 0 0;
    }
}

.flex-spacer {
    flex-grow: 1;
}

ul {
    padding-left: 24px;

    li::marker {
        font-size: 14px;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

pre {
    position: relative;
    background-color: #222;
    padding: 16px;
    margin: 32px 16px 16px;
    top: -16px;
    white-space: pre-wrap;
    color: white;
    border-radius: 8px;
    font-family: monospace;
}
