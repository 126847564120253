[type='search'] {
    appearance: textfield;

    &::-webkit-search-decoration {
        appearance: none;
    }
}

[type='text'],
[type='number'],
[type='password'],
[type='search'],
[type='email'],
[type='tel'],
[type='url'],
textarea {
    display: block;
    margin: 0;
    border: 1px solid var(--border);
    border-radius: 5px;
    padding: 12px;
    width: 100%;
    line-height: 24px;
    color: inherit;

    font-size: 16px;
    appearance: none;
    box-sizing: border-box;

    &::placeholder {
        color: var(--text-light);
    }

    &:hover {
        outline: 0;
        border: 1px solid var(--grey);
    }

    &:focus {
        outline: 0;
        border: 1px solid var(--border-active);
    }
}

textarea {
    display: block;
    overflow: auto;
    resize: vertical;
}

select {
    @extend %select-base;
}
